<template>
  <div>
    <v-select
      :items="currentPossibleTools"
      :value="chosenTool"
      :label="$t('toolToBeSynced')"
      filled
      dense
      @change="prepareToBeFilled"
    ></v-select>
    <template v-if="chosenTool === ''"></template>
    <template v-else-if="chosenTool === togglTrack">
      <v-form>
        <v-card>
          <v-row>
            <v-col cols="100%">
              <v-col>
                <v-text-field
                  v-model="togglTrackApiKey"
                  :label="$t('togglTrackForm.apiKey')"
                  dense
                  @keyup="togglTrackApiKeyChanged"
                  @change="togglTrackApiKeyChanged"
                  :error-messages="togglTrackApiKeyErrorMessages"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="
                            openToolAPIKeyPage(
                              'https://track.toggl.com/profile'
                            )
                          "
                        >
                          mdi-help-circle-outline</v-icon
                        >
                      </template>
                      {{ $t("toolTips.togglTrackApiKey") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <div v-if="togglTrackStatus === 'success'">
                      <v-icon color="success" v-on="on"> mdi-check</v-icon>
                    </div>
                    <div v-else-if="togglTrackStatus === 'error'">
                      <v-icon color="error" v-on="on"> mdi-close</v-icon>
                    </div>
                    <div v-else-if="togglTrackStatus === 'loading'">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        size="20"
                        width="2"
                        v-on="on"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedTogglTrackWorkspace"
                  :items="toggTrackPossibleWorkspaces"
                  item-text="name"
                  item-value="id"
                  :value="selectedTogglTrackWorkspace"
                  :label="$t('togglTrackForm.workspace')"
                  dense
                  @change="togglTrackWorkspaceChanged"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                      </template>
                      {{ $t("toolTips.workspace") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <v-icon
                      color="success"
                      v-if="selectedTogglTrackWorkspace !== ''"
                      v-on="on"
                    >
                      mdi-check
                    </v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </template>
    <template v-else-if="chosenTool === redmine">
      <v-form>
        <v-card>
          <v-row>
            <v-col cols="100%">
              <v-col>
                <v-text-field
                  v-model="redmineApiPoint"
                  :label="$t('redmineForm.apiPoint')"
                  dense
                  @keyup="redmineApiKeyOrApiPointChanged"
                  @change="redmineApiKeyOrApiPointChanged"
                  :error-messages="redmineApiPointErrorMessages"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                      </template>
                      {{ $t("toolTips.apiPoint") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <div v-if="redmineApiPointStatus === 'success'">
                      <v-icon color="success" v-on="on"> mdi-check</v-icon>
                    </div>
                    <div v-else-if="redmineApiPointStatus === 'error'">
                      <v-icon color="error" v-on="on"> mdi-close</v-icon>
                    </div>
                    <div v-else-if="redmineApiPointStatus === 'loading'">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        size="20"
                        width="2"
                        v-on="on"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="redmineApiKey"
                  :label="$t('redmineForm.apiKey')"
                  dense
                  @keyup="redmineApiKeyOrApiPointChanged"
                  @change="redmineApiKeyOrApiPointChanged"
                  :error-messages="redmineApiKeyErrorMessages"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                      </template>
                      {{ $t("toolTips.redmineApiKey") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <div v-if="redmineApiKeyStatus === 'success'">
                      <v-icon color="success" v-on="on"> mdi-check</v-icon>
                    </div>
                    <div v-else-if="redmineApiKeyStatus === 'error'">
                      <v-icon color="error" v-on="on"> mdi-close</v-icon>
                    </div>
                    <div v-else-if="redmineApiKeyStatus === 'loading'">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        size="20"
                        width="2"
                        v-on="on"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="selectedRedmineDefaultTimeEntryActivity"
                  :items="redminePossibleDefaultTimeEntryActivities"
                  item-text="name"
                  item-value="id"
                  :value="selectedRedmineDefaultTimeEntryActivity"
                  :label="$t('redmineForm.defaultTimeEntryActivity')"
                  dense
                  @change="redmineDefaultTimeEntryActivityChanged"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                      </template>
                      {{ $t("toolTips.defaultTimeEntryActivity") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <v-icon
                      color="success"
                      v-if="selectedRedmineDefaultTimeEntryActivity !== ''"
                      v-on="on"
                    >
                      mdi-check
                    </v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </template>
    <template v-else-if="chosenTool === jira">
      <v-form>
        <v-card>
          <v-row>
            <v-col cols="100%">
              <v-col>
                <v-text-field
                  v-model="jiraDomain"
                  :label="$t('jiraForm.domain')"
                  dense
                  @keyup="jiraApiKeyOrDomainOrUserEmailChanged"
                  @change="jiraApiKeyOrDomainOrUserEmailChanged"
                  :error-messages="jiraDomainErrorMessages"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                      </template>
                      {{ $t("toolTips.jiraDomain") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <div v-if="jiraDomainStatus === 'success'">
                      <v-icon color="success" v-on="on"> mdi-check</v-icon>
                    </div>
                    <div v-else-if="jiraDomainStatus === 'error'">
                      <v-icon color="error" v-on="on"> mdi-close</v-icon>
                    </div>
                    <div v-else-if="jiraDomainStatus === 'loading'">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        size="20"
                        width="2"
                        v-on="on"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="jiraApiKey"
                  :label="$t('jiraForm.apiKey')"
                  dense
                  @keyup="jiraApiKeyOrDomainOrUserEmailChanged"
                  @change="jiraApiKeyOrDomainOrUserEmailChanged"
                  :error-messages="jiraApiKeyErrorMessages"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="
                            openToolAPIKeyPage(
                              'https://id.atlassian.com/manage-profile/security/api-tokens'
                            )
                          "
                        >
                          mdi-help-circle-outline</v-icon
                        >
                      </template>
                      {{ $t("toolTips.jiraApiKey") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <div v-if="jiraApiKeyStatus === 'success'">
                      <v-icon color="success" v-on="on"> mdi-check</v-icon>
                    </div>
                    <div v-else-if="jiraApiKeyStatus === 'error'">
                      <v-icon color="error" v-on="on"> mdi-close</v-icon>
                    </div>
                    <div v-else-if="jiraApiKeyStatus === 'loading'">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        size="20"
                        width="2"
                        v-on="on"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="jiraUserEmail"
                  :label="$t('jiraForm.userEmail')"
                  dense
                  @keyup="jiraApiKeyOrDomainOrUserEmailChanged"
                  @change="jiraApiKeyOrDomainOrUserEmailChanged"
                  :error-messages="jiraUserEmailErrorMessages"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                      </template>
                      {{ $t("toolTips.jiraUserEmail") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <div v-if="jiraUserEmailStatus === 'success'">
                      <v-icon color="success" v-on="on"> mdi-check</v-icon>
                    </div>
                    <div v-else-if="jiraUserEmailStatus === 'error'">
                      <v-icon color="error" v-on="on"> mdi-close</v-icon>
                    </div>
                    <div v-else-if="jiraUserEmailStatus === 'loading'">
                      <v-progress-circular
                        indeterminate
                        color="primary"
                        size="20"
                        width="2"
                        v-on="on"
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="!project2project">
                <v-checkbox
                  :label="$t('jiraForm.createFallbackTask')"
                  @change="jiraFallbackCheckedChanged"
                  dense
                  :input-value="jiraFallbackIssue"
                >
                </v-checkbox>
              </v-col>
              <v-col v-if="jiraFallbackIssue">
                <v-text-field
                  v-model="jiraFallbackIssueName"
                  :label="$t('jiraForm.fallbackTask')"
                  dense
                  @keyup="jiraFallbackIssueNameChanged"
                  @change="jiraFallbackIssueNameChanged"
                  :error-messages="jiraFallbackIssueNameErrorMessage"
                >
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on"> mdi-help-circle-outline</v-icon>
                      </template>
                      {{ $t("toolTips.jiraFallbackTask") }}
                    </v-tooltip>
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:append v-slot:activator="{ on }">
                    <div v-if="jiraFallbackIssueName.length >= 1">
                      <v-icon color="success" v-on="on"> mdi-check</v-icon>
                    </div>
                    <div v-else-if="jiraFallbackIssueName.length < 1">
                      <v-icon color="error" v-on="on"> mdi-close</v-icon>
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </template>
  </div>
</template>

<script>
import { ToolType } from "@/enums/tools/typeOfTool";
import axios from "axios";
import { Constants } from "@/constants/Constants";
import { EventBus } from "../../../EventBus";

export default {
  data() {
    return {
      // Global variables
      redmine: ToolType.REDMINE.name,
      togglTrack: ToolType.TOGGL_TRACK.name,
      jira: ToolType.JIRA.name,
      chosenTool: "",
      debounceDelay: 600, // ms

      // Connection box
      currentConnectionBox: null,

      // Redmine related variables
      redmineApiPointStatus: "",
      redmineApiKeyStatus: "",
      lastProcessedRedmineApiPointStatus: "error",
      lastProcessedRedmineApiKeyStatus: "error",
      redmineDebounceTimer: null,
      redmineApiKeyErrorMessages: [],
      redmineApiPointErrorMessages: [],

      redmineApiKey: "",
      lastProcessedRedmineApiKey: "",
      redmineApiPoint: "",
      lastProcessedRedmineApiPoint: "",

      redmineUserId: "",
      redminePossibleDefaultTimeEntryActivities: [],
      selectedRedmineDefaultTimeEntryActivity: "",

      configuredRedmine: null,

      // Toggl Track related variables
      togglTrackStatus: "",
      lastProcessedTogglTrackStatus: "error",
      togglTrackDebounceTimer: null,
      togglTrackApiKeyErrorMessages: [],

      togglTrackApiKey: "",
      lastProcessedTogglTrackApiKey: "",

      togglTrackUserId: "",
      toggTrackPossibleWorkspaces: [],
      selectedTogglTrackWorkspace: "",

      configuredToggleTrack: null,

      //Jira related varibles
      jiraApiKey: "",
      jiraDomain: "",
      jiraUserEmail: "",
      jiraDebounceTimer: null,

      jiraDomainErrorMessages: [],
      jiraDomainStatus: "",

      jiraApiKeyErrorMessages: [],
      jiraApiKeyStatus: "",

      jiraUserEmailErrorMessages: [],
      jiraUserEmailStatus: "",

      jiraFallbackIssue: false,
      jiraFallbackIssueName: "",
      jiraFallbackIssueNameErrorMessage: [],
    };
  },
  methods: {
    prepareToBeFilled(chosen) {
      this.currentConnectionBox.tool = chosen;
      this.disableSaveButton();
      this.chosenTool = chosen;
      if (chosen === this.redmine) {
        this.emitConfiguredRedmine();
      } else if (chosen === this.togglTrack) {
        this.emitConfiguredTogglTrack();
      } else if (chosen === this.jira) {
        this.emitConfiguredJira();
      }
      this.$emit("chosen-tool", chosen);
    },

    // Toggl Track methods
    togglTrackApiKeyChanged() {
      this.currentConnectionBox.togglTrackApiKey = this.togglTrackApiKey;
      this.disableSaveButton();
      clearTimeout(this.togglTrackDebounceTimer);
      if (this.togglTrackApiKey === "") {
        this.togglTrackStatus = "error";
        this.processTogglTrackApiKeyChanged();
        return;
      }
      this.togglTrackStatus = "loading";
      this.togglTrackDebounceTimer = setTimeout(() => {
        if (this.togglTrackApiKey === this.lastProcessedTogglTrackApiKey) {
          this.togglTrackStatus = this.lastProcessedTogglTrackStatus;
          this.emitConfiguredTogglTrack();
          return;
        }
        this.getToggleTrackWorkspaces(this.togglTrackApiKey);
      }, this.debounceDelay);
    },

    async getToggleTrackWorkspaces(apiKey) {
      await this.fetchTogglTrackWorkspaces(apiKey);

      this.dispatchCurrentConnectionBox();
      this.lastProcessedTogglTrackApiKey = apiKey;
      this.lastProcessedTogglTrackStatus = this.togglTrackStatus;

      this.processTogglTrackApiKeyChanged();
    },

    async fetchTogglTrackWorkspaces(apiKey) {
      const accessToken = await this.$auth.getTokenSilently();

      const data = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          api_key: apiKey,
        },
      };

      this.selectedTogglTrackWorkspace = "";
      this.toggTrackPossibleWorkspaces = [];

      await axios
        .get(
          `${Constants.BACKEND_API_URL}synced_services_config/toggl_track_workspaces`,
          data
        )
        .then((response) => {
          if (response.status === 200) {
            this.togglTrackStatus = "success";
            this.togglTrackUserId = response.data.user_id;
            this.currentConnectionBox.userId = this.togglTrackUserId;
            this.toggTrackPossibleWorkspaces = response.data.workspaces;
          } else {
            this.togglTrackStatus = "error";
          }
        })
        .catch((error) => {
          this.togglTrackStatus = "error";
        });
    },

    processTogglTrackApiKeyChanged() {
      if (this.togglTrackApiKey === "") {
        this.toggTrackPossibleWorkspaces = [];
        this.selectedTogglTrackWorkspace = "";
        this.togglTrackApiKeyErrorMessages = [
          this.$t("rules.togglTrackApiKey"),
        ];
      } else if (this.togglTrackStatus === "error") {
        this.toggTrackPossibleWorkspaces = [];
        this.selectedTogglTrackWorkspace = "";
        this.togglTrackApiKeyErrorMessages = [
          this.$t("rules.InvalidTogglTrackApiKey"),
        ];
      } else if (this.togglTrackStatus === "success") {
        this.togglTrackApiKeyErrorMessages = [];
      }
    },

    togglTrackWorkspaceChanged(changedWorkspace) {
      this.currentConnectionBox.selectedTogglTrackWorkspace = changedWorkspace;
      this.currentConnectionBox.selectedTogglTrackWorkspaceName =
        this.toggTrackPossibleWorkspaces.find((workspace) => {
          return workspace.id === changedWorkspace;
        }).name;
      this.currentConnectionBox.userId = this.togglTrackUserId;
      this.emitConfiguredTogglTrack();
    },

    emitConfiguredTogglTrack() {
      this.dispatchCurrentConnectionBox();
      if (
        !this.toggTrackPossibleWorkspaces ||
        !this.selectedTogglTrackWorkspace
      ) {
        return;
      }

      this.$emit("tool-configured", true);
    },

    // Redmine methods
    redmineApiKeyOrApiPointChanged() {
      this.currentConnectionBox.redmineApiKey = this.redmineApiKey;
      this.currentConnectionBox.redmineApiPoint = this.redmineApiPoint;
      this.disableSaveButton();
      clearTimeout(this.redmineDebounceTimer);
      if (this.redmineApiKey === "" || this.redmineApiPoint === "") {
        this.processRedmineApiKeyOrApiPointChanged();
        return;
      }
      this.redmineApiKeyStatus = "loading";
      this.redmineApiPointStatus = "loading";
      this.redmineDebounceTimer = setTimeout(() => {
        if (
          this.redmineApiKey === this.lastProcessedTogglTrackApiKey &&
          this.redmineApiPoint === this.lastProcessedRedmineApiPoint
        ) {
          this.redmineApiKeyStatus = this.lastProcessedRedmineApiKeyStatus;
          this.redmineApiPointStatus = this.lastProcessedRedmineApiPointStatus;
          this.emitConfiguredRedmine();
          return;
        }
        this.getRedmineTimeEntries(this.redmineApiPoint, this.redmineApiKey);
      }, this.debounceDelay);
    },

    async getRedmineTimeEntries(apiPoint, apiKey) {
      await this.fetchRedmineTimeEntries(apiPoint, apiKey);

      this.dispatchCurrentConnectionBox();
      this.lastProcessedTogglTrackApiKey = apiKey;
      this.lastProcessedRedmineApiPoint = apiPoint;
      this.lastProcessedRedmineApiKeyStatus = this.redmineApiKeyStatus;
      this.lastProcessedRedmineApiPointStatus = this.redmineApiPointStatus;

      this.processRedmineApiKeyOrApiPointChanged();
    },

    async fetchRedmineTimeEntries(apiPoint, apiKey) {
      const accessToken = await this.$auth.getTokenSilently();
      const data = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          api_key: apiKey,
          api_point: apiPoint,
        },
      };

      this.selectedRedmineDefaultTimeEntryActivity = "";
      this.redminePossibleDefaultTimeEntryActivities = [];

      await axios
        .get(
          `${Constants.BACKEND_API_URL}synced_services_config/redmine_time_entry_activities`,
          data
        )
        .then((response) => {
          if (response.status === 200) {
            this.redmineApiPointStatus = "success";
            this.redmineApiKeyStatus = "success";

            this.redmineUserId = response.data.user_id;
            this.currentConnectionBox.userId = this.redmineUserId;
            this.redminePossibleDefaultTimeEntryActivities =
              response.data.time_entry_activities;
          } else {
            this.redmineApiPointStatus = "error";
            this.redmineApiKeyStatus = "error";
          }
        })
        .catch((error) => {
          this.redmineApiPointStatus = "error";
          this.redmineApiKeyStatus = "error";
        });
    },

    processRedmineApiKeyOrApiPointChanged() {
      if (this.redmineApiKey === "" || this.redmineApiPoint === "") {
        this.redminePossibleDefaultTimeEntryActivities = [];
        this.selectedRedmineDefaultTimeEntryActivity = "";

        // clear all
        this.redmineApiPointStatus = "error";
        this.redmineApiPointErrorMessages = [this.$t("rules.apiPoint")];
        this.redmineApiKeyStatus = "error";
        this.redmineApiKeyErrorMessages = [this.$t("rules.redmineApiKey")];

        // set error messages and status
        if (this.redmineApiKey !== "") {
          this.redmineApiKeyStatus = "";
          this.redmineApiKeyErrorMessages = [];
        }
        if (this.redmineApiPoint !== "") {
          this.redmineApiPointStatus = "";
          this.redmineApiPointErrorMessages = [];
        }
      } else if (
        this.redmineApiKeyStatus === "error" ||
        this.redmineApiPointStatus === "error"
      ) {
        this.redminePossibleDefaultTimeEntryActivities = [];
        this.selectedRedmineDefaultTimeEntryActivity = "";

        // set error messages and status
        if (this.redmineApiKeyStatus === "error") {
          this.redmineApiKeyErrorMessages = [
            this.$t("rules.invalidRedmineApiKey"),
          ];
        }
        if (this.redmineApiPointStatus === "error") {
          this.redmineApiPointErrorMessages = [
            this.$t("rules.invalidRedmineApiPoint"),
          ];
        }
      } else if (
        this.redmineApiKeyStatus === "success" &&
        this.redmineApiPointStatus === "success"
      ) {
        // set error messages and status
        this.redmineApiKeyErrorMessages = [];
        this.redmineApiPointErrorMessages = [];
      }
    },

    redmineDefaultTimeEntryActivityChanged(changedDefaultTimeEntryActivity) {
      this.currentConnectionBox.selectedRedmineDefaultTimeEntryActivity =
        changedDefaultTimeEntryActivity;
      this.currentConnectionBox.selectedRedmineDefaultTimeEntryActivityName =
        this.redminePossibleDefaultTimeEntryActivities.find(
          (activity) => activity.id === changedDefaultTimeEntryActivity
        ).name;
      this.currentConnectionBox.userId = this.redmineUserId;
      this.emitConfiguredRedmine();
    },

    emitConfiguredRedmine() {
      this.dispatchCurrentConnectionBox();
      if (
        !this.redminePossibleDefaultTimeEntryActivities ||
        !this.selectedRedmineDefaultTimeEntryActivity
      ) {
        return;
      }
      this.$emit("tool-configured", true);
    },

    // Connection box methods
    getCurrentConnectionBox() {
      let connectionBox = null;
      for (let i = 0; i < this.$store.state.userConnectionBoxes.length; ++i) {
        if (
          this.connectionBoxId ===
          this.$store.state.userConnectionBoxes[i].connectionBoxId
        ) {
          connectionBox = this.$store.state.userConnectionBoxes[i];
          break;
        }
      }
      return connectionBox;
    },

    dispatchCurrentConnectionBox() {
      const connectionBoxes = this.$store.state.userConnectionBoxes;
      for (let pos = 0; pos < connectionBoxes.length; ++pos) {
        if (connectionBoxes[pos].connectionBoxId === this.connectionBoxId) {
          if (this.numberOfTool === "first") {
            connectionBoxes[pos].firstTool = this.currentConnectionBox;
          } else {
            connectionBoxes[pos].secondTool = this.currentConnectionBox;
          }
          break;
        }
      }
      this.$store.dispatch("changeUserConnectionBoxes", connectionBoxes);
    },

    async fetchConnectionBox(isSaved = false) {
      const currentConnectionBox = this.getCurrentConnectionBox();

      this.currentConnectionBox =
        this.numberOfTool === "first"
          ? currentConnectionBox.firstTool
          : currentConnectionBox.secondTool;

      if (!isSaved) {
        if (this.currentConnectionBox.hasOwnProperty("tool")) {
          this.chosenTool = this.currentConnectionBox.tool;
          this.$emit("chosen-tool", this.chosenTool);
        }
        //toggl
        this.currentConnectionBox.hasOwnProperty("togglTrackApiKey")
          ? (this.togglTrackApiKey = this.currentConnectionBox.togglTrackApiKey)
          : "";
        if (this.togglTrackApiKey) {
          this.togglTrackStatus = "loading";
          await this.getToggleTrackWorkspaces(this.togglTrackApiKey);
          this.currentConnectionBox.hasOwnProperty(
            "selectedTogglTrackWorkspace"
          )
            ? (this.selectedTogglTrackWorkspace =
                this.currentConnectionBox.selectedTogglTrackWorkspace)
            : "";
          this.emitConfiguredTogglTrack();
        }
        //redmine
        this.currentConnectionBox.hasOwnProperty("redmineApiKey")
          ? (this.redmineApiKey = this.currentConnectionBox.redmineApiKey)
          : "";
        this.currentConnectionBox.hasOwnProperty("redmineApiPoint")
          ? (this.redmineApiPoint = this.currentConnectionBox.redmineApiPoint)
          : "";
        if (this.redmineApiKey && this.redmineApiPoint) {
          this.redmineApiKeyStatus = "loading";
          this.redmineApiPointStatus = "loading";
          await this.getRedmineTimeEntries(
            this.redmineApiPoint,
            this.redmineApiKey
          );
          this.currentConnectionBox.hasOwnProperty(
            "selectedRedmineDefaultTimeEntryActivity"
          )
            ? (this.selectedRedmineDefaultTimeEntryActivity =
                this.currentConnectionBox.selectedRedmineDefaultTimeEntryActivity)
            : "";
          this.emitConfiguredRedmine();
        }
        //jira
        this.currentConnectionBox.hasOwnProperty("jiraDomain")
          ? (this.jiraDomain = this.currentConnectionBox.jiraDomain)
          : "";
        this.currentConnectionBox.hasOwnProperty("jiraUserEmail")
          ? (this.jiraUserEmail = this.currentConnectionBox.jiraUserEmail)
          : "";
        this.currentConnectionBox.hasOwnProperty("jiraApiKey")
          ? (this.jiraApiKey = this.currentConnectionBox.jiraApiKey)
          : "";
        this.currentConnectionBox.hasOwnProperty("jiraFallbackIssue")
          ? (this.jiraFallbackIssue =
              this.currentConnectionBox.jiraFallbackIssue)
          : false;
        if (this.jiraFallbackIssue) {
          this.currentConnectionBox.hasOwnProperty("jiraFallbackIssueName")
            ? (this.jiraFallbackIssueName =
                this.currentConnectionBox.jiraFallbackIssueName)
            : "";
        }

        if (this.jiraApiKey && this.jiraDomain && this.jiraUserEmail) {
          this.jiraApiKeyStatus = "loading";
          this.jiraDomainStatus = "loading";
          this.jiraUserEmailStatus = "loading";
          const jiraConnectionOK = await this.checkJiraConnection(
            this.jiraApiKey,
            this.jiraDomain,
            this.jiraUserEmail
          );
          if (this.jiraConnectionOK) {
            this.emitConfiguredJira();
          }
        }
      }
    },

    disableSaveButton() {
      this.$emit("tool-configured", false);
    },

    //Jira methods
    jiraApiKeyOrDomainOrUserEmailChanged() {
      this.currentConnectionBox.jiraApiKey = this.jiraApiKey;
      this.currentConnectionBox.jiraDomain = this.jiraDomain;
      this.currentConnectionBox.jiraUserEmail = this.jiraUserEmail;
      this.currentConnectionBox.userId = this.userId;
      this.disableSaveButton();
      clearTimeout(this.jiraDebounceTimer);
      if (
        this.jiraApiKey === "" ||
        this.jiraDomain === "" ||
        this.jiraUserEmail === ""
      ) {
        this.processJiraApiKeyOrDomainOrUserEmailChange();
        return;
      }
      this.jiraApiKeyStatus = "loading";
      this.jiraDomainStatus = "loading";
      this.jiraUserEmailStatus = "loading";

      this.jiraDebounceTimer = setTimeout(() => {
        this.checkJiraConnection(
          this.jiraApiKey,
          this.jiraDomain,
          this.jiraUserEmail
        );
      }, this.debounceDelay);
    },

    async checkJiraConnection(apiKey, domain, userEmail) {
      this.disableSaveButton();
      const accessToken = await this.$auth.getTokenSilently();

      const data = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        params: {
          api_key: apiKey,
          domain: domain,
          user_email: userEmail,
        },
      };
      await axios
        .get(
          `${Constants.BACKEND_API_URL}synced_services_config/check_jira_connection`,
          data
        )
        .then((response) => {
          if (response.status === 200) {
            this.userId = response.data;
            this.currentConnectionBox.userId = this.userId;
            this.jiraApiKeyStatus = "success";
            this.jiraDomainStatus = "success";
            this.jiraUserEmailStatus = "success";
            this.jiraApiKeyErrorMessages = [];
            this.jiraDomainErrorMessages = [];
            this.jiraUserEmailErrorMessages = [];
            this.emitConfiguredJira();
          } else {
            this.jiraDomainStatus = "error";
            this.jiraApiKeyStatus = "error";
            this.jiraUserEmailStatus = "error";
            this.jiraApiKeyErrorMessages = [this.$t("rules.invalidJira")];
            this.jiraDomainErrorMessages = [this.$t("rules.invalidJira")];
            this.jiraUserEmailErrorMessages = [this.$t("rules.invalidJira")];
          }
        })
        .catch((error) => {
          this.jiraApiKeyStatus = "error";
          this.jiraDomainStatus = "error";
          this.jiraUserEmailStatus = "error";
          this.jiraApiKeyErrorMessages = [this.$t("rules.invalidJira")];
          this.jiraDomainErrorMessages = [this.$t("rules.invalidJira")];
          this.jiraUserEmailErrorMessages = [this.$t("rules.invalidJira")];
        });
    },

    processJiraApiKeyOrDomainOrUserEmailChange() {
      if (
        this.jiraApiKey === "" ||
        this.jiraDomain === "" ||
        this.jiraUserEmail === ""
      ) {
        // clear all
        this.jiraDomainStatus = "error";
        this.jiraDomainErrorMessages = [this.$t("rules.jiraDomain")];
        this.jiraApiKeyStatus = "error";
        this.jiraApiKeyErrorMessages = [this.$t("rules.jiraApiKey")];
        this.jiraUserEmailStatus = "error";
        this.jiraUserEmailErrorMessages = [this.$t("rules.jiraUserEmail")];

        // set error messages and status
        if (this.jiraApiKey !== "") {
          this.jiraApiKeyStatus = "";
          this.jiraApiKeyErrorMessages = [];
        }
        if (this.jiraDomain !== "") {
          this.jiraDomainStatus = "";
          this.jiraDomainErrorMessages = [];
        }
        if (this.jiraUserEmail !== "") {
          this.jiraUserEmailStatus = "";
          this.jiraUserEmailErrorMessages = [];
        }
      } else if (
        this.jiraApiKeyStatus === "error" ||
        this.jiraDomainStatus === "error" ||
        this.jiraUserEmailStatus === "error"
      ) {
        // set error messages and status
        if (this.jiraApiKeyStatus === "error") {
          this.jiraApiKeyErrorMessages = [this.$t("rules.invalidJira")];
        }
        if (this.jiraDomainStatus === "error") {
          this.jiraDomainErrorMessages = [this.$t("rules.invalidJira")];
        }
        if (this.jiraUserEmailStatus === "error") {
          this.jiraUserEmailErrorMessages = [this.$t("rules.invalidJira")];
        }
      } else if (
        this.jiraApiKeyStatus === "success" &&
        this.jiraDomainStatus === "success" &&
        this.jiraUserEmailStatus === "success"
      ) {
        // set error messages and status
        this.jiraApiKeyErrorMessages = [];
        this.jiraDomainErrorMessages = [];
        this.jiraUserEmailErrorMessages = [];
      }
    },
    jiraFallbackCheckedChanged() {
      this.jiraFallbackIssue = !this.jiraFallbackIssue;
      this.currentConnectionBox.jiraFallbackIssue = this.jiraFallbackIssue;
      if (!this.jiraFallbackIssue) this.emitConfiguredJira();
      if (this.jiraFallbackIssue) this.disableSaveButton();
    },
    jiraFallbackIssueNameChanged() {
      this.currentConnectionBox.jiraFallbackIssue = this.jiraFallbackIssue;
      this.currentConnectionBox.jiraFallbackIssueName =
        this.jiraFallbackIssueName;
      if (this.jiraFallbackIssueName === "") {
        this.disableSaveButton();
        this.jiraFallbackIssueNameErrorMessage = [
          this.$t("rules.jiraFallbackTaskNameEmpty"),
        ];
      } else {
        this.jiraFallbackIssueNameErrorMessage = [];
        this.emitConfiguredJira();
      }
    },

    emitConfiguredJira() {
      this.dispatchCurrentConnectionBox();
      if (
        !this.jiraDomain ||
        !this.jiraApiKey ||
        !this.jiraUserEmail ||
        !this.userId
      ) {
        return;
      }
      this.$emit("tool-configured", true);
    },
    openToolAPIKeyPage(url) {
      const a = document.createElement("a");
      a.href = url;
      a.target = "_blank";
      const e = new MouseEvent("click", {
        ctrlKey: true, // for Windows or Linux
        metaKey: true, // for MacOS
      });
      a.dispatchEvent(e);
    },
  },
  async created() {
    await this.fetchConnectionBox();
  },
  mounted() {
    EventBus.$on("connection-box-saved", async (oldId, newId) => {
      if (oldId === this.connectionBoxId) {
        this.connectionBoxId = newId;
        await this.fetchConnectionBox(true);
      }
    });
  },
  props: [
    "currentPossibleTools",
    "connectionBoxId",
    "numberOfTool",
    "project2project",
  ],
};
</script>

<style scoped></style>
